export enum EVENT_TYPE {
  INSTITUTE = 1,
  SEMINAR = 2,
  WORKSHOP = 3,
  CONFERENCE_DAY = 4,
  PRINCIPAL_CONF = 7
}

export enum QueryReturnState {
  ERROR = 'ERROR',
  NOT_FOUND = 'NOT_FOUND',
  SUCCESS = 'SUCCESS',
  UNAUTHORIZED = 'UNAUTHORIZED'
}
