import React, { createContext, useState } from 'react';

interface ApplicationProviderProps {
  children: React.ReactChildren;
}

const ApplicationContext = createContext<{
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: any;
  isApplicationSubmitted: boolean;
  setIsApplicationSubmitted: any;
}>({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  isApplicationSubmitted: false,
  setIsApplicationSubmitted: () => {}
});
const { Provider } = ApplicationContext;

const ApplicationProvider = (props: ApplicationProviderProps) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isApplicationSubmitted, setIsApplicationSubmitted] = useState(false);
  return (
    <Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges,
        isApplicationSubmitted,
        setIsApplicationSubmitted
      }}
    >
      {props.children}
    </Provider>
  );
};

export { ApplicationContext, ApplicationProvider };
