import { EVENT_TYPE } from './Enum';
import moment from 'moment';
import { OrganizationPackage } from './OrganizationPackageTypes';

export const getDates = (section: any, ticket: any) => {
  return section.EventSectionDates.length
    ? section.EventSectionDates
    : [ticket.Event.StartDate];
};

export function getOrgDevSectionByDevSection(
  organizationPackage: OrganizationPackage,
  developerSectionID: number
) {
  return organizationPackage.OrganizationPackageDeveloperGroupSections.find(
    p => p.DeveloperSectionID === developerSectionID
  );
}

export function shouldShowEvent(eventTypeID: number, packageTicket: any) {
  return (
    (eventTypeID !== EVENT_TYPE.CONFERENCE_DAY &&
      eventTypeID !== EVENT_TYPE.PRINCIPAL_CONF) ||
    ((eventTypeID == EVENT_TYPE.CONFERENCE_DAY ||
      eventTypeID == EVENT_TYPE.PRINCIPAL_CONF) &&
      (packageTicket.Ticket.TicketBundle ||
        !packageTicket.AllowApplicantAllocation))
  );
}

let userTypes = {
  admin: 'Administrator',
  institute_admin: 'Project Coordinator',
  office: 'Office',
  staff_developer: 'Staff Developer',
  treasure_chest: 'Treasure Chest',
  finance: 'Finance',
  event_finance: 'Event Finance',
  project_school_administrator: 'Project School Administrator',
  central_school_administrator: 'Central School Administrator',
  affiliate_school_administrator: 'Affiliate School Administrator',
  air_treasure_chest: 'AIR Treasure Chest'
};

export function parseUser(user: any) {
  var parsedUser = null;

  if (user != null) {
    parsedUser = {
      // User
      auth: user.SecurityStamp,
      background: user.Background,
      email: user.Email,
      firstname: user.FirstName,
      id: user.UserID,
      lastname: user.LastName,
      name: user.FullName,
      password: user.Password,
      phone: user.Phone,
      dateofbirth: user.DateOfBirth
        ? moment(user.DateOfBirth).utc().format('M/D/YYYY')
        : '',
      ssnlastfour: user.SSNLastFour,
      organizationUsers: user.OrganizationUsers || [],
      // UserTypes
      access: {
        admin: user.schoolLeaderAccess,
        institute_admin: false,
        office: false,
        staff_developer: false,
        treasure_chest: user.toolKitAccess,
        finance: false,
        event_finance: false,
        project_school_administrator: user.schoolLeaderAccess,
        central_school_administrator: user.schoolLeaderAccess,
        affiliate_school_administrator: user.schoolLeaderAccess,
        air_treasure_chest: false
      },
      // Location
      address: null,
      address1: null,
      address2: null,
      city: null,
      country: null,
      state: null,
      zip: null
    };

    parsedUser.address = user.address;
    parsedUser.address1 = user.address1;
    parsedUser.address2 = user.address2;
    parsedUser.city = user.city;
    parsedUser.country = user.country;
    parsedUser.state = user.stateProvince;
    parsedUser.zip = user.zipPostal;

    if (user.UserTypes != null) {
      for (var index = 0; index < user.UserTypes.length; index++) {
        if (user.UserTypes[index].Active) {
          switch (user.UserTypes[index].Description) {
            case userTypes.admin:
              parsedUser.access.admin = true;
              break;
            case userTypes.institute_admin:
              parsedUser.access.institute_admin = true;
              break;
            case userTypes.office:
              parsedUser.access.office = true;
              break;
            case userTypes.staff_developer:
              parsedUser.access.staff_developer = true;
              break;
            case userTypes.treasure_chest:
              parsedUser.access.treasure_chest = true;
              break;
            case userTypes.finance:
              parsedUser.access.finance = true;
              break;
            case userTypes.event_finance:
              parsedUser.access.event_finance = true;
              break;
            case userTypes.project_school_administrator:
              parsedUser.access.project_school_administrator = true;
              break;

            case userTypes.central_school_administrator:
              parsedUser.access.central_school_administrator = true;
              break;
            case userTypes.affiliate_school_administrator:
              parsedUser.access.affiliate_school_administrator = true;
              break;
            case userTypes.air_treasure_chest:
              parsedUser.access.air_treasure_chest = true;
              break;
          }
        }
      }
    }
  }

  return parsedUser;
}
