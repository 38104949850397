import React, { createContext, useState } from 'react';
import { PackageRequest } from '../components/members/NewApiTypes.generated';

interface PackageProviderProps {
  children: React.ReactChildren;
}

const PackageContext = createContext<{
  hasUnsavedChanges: boolean;
  setHasUnsavedChanges: any;
  isPackageEditable: boolean;
  setIsPackageEditable: any;
  organizationId: string;
  setOrganizationId: any;
  packageRequest?: PackageRequest;
  setPackageRequest: any;
}>({
  hasUnsavedChanges: false,
  setHasUnsavedChanges: () => {},
  isPackageEditable: false,
  setIsPackageEditable: () => {},
  organizationId: '',
  setOrganizationId: () => {},
  packageRequest: undefined,
  setPackageRequest: () => {}
});
const { Provider } = PackageContext;

const PackageProvider = (props: PackageProviderProps) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [isPackageEditable, setIsPackageEditable] = useState(false);
  const [organizationId, setOrganizationId] = useState('');
  const [packageRequest, setPackageRequest] = useState();
  return (
    <Provider
      value={{
        hasUnsavedChanges,
        setHasUnsavedChanges,
        isPackageEditable,
        setIsPackageEditable,
        organizationId,
        setOrganizationId,
        packageRequest,
        setPackageRequest
      }}
    >
      {props.children}
    </Provider>
  );
};

export { PackageContext, PackageProvider };
